<template>
    <vue2-dropzone
        id="dropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        useCustomSlot
        v-on:vdropzone-sending="sendingEvent"
        v-on:vdropzone-success="fileSuccess"
        v-on:vdropzone-complete="fileCompleted"
        v-on:vdropzone-files-added="filesAdded"
    >
        <div id="documentDropZone" class="dz-dropzone p-1" v-show="!isUploading">Excel zum Upload hierhin ziehen</div>

    </vue2-dropzone>
</template>



<script>

import vue2Dropzone  from 'vue2-dropzone'
import {authHeader} from "@utilities/functions";


export default {

    props: {
        options: { type: Object}
    },

    computed: {
        template: function () {
            return `<div class="dz-preview dz-file-preview mt-0">
                        <div class="dz-details is-flex">
                            <span data-dz-name></span>
                            <div class="dz-error-message ml-1">
                                <span data-dz-errormessage></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>

                        <div class="dz-remove ml-1">
                            <i class="material-icons" data-dz-remove>delete</i>
                        </div>
                    </div>`;
        },
        dropzoneOptions: function () {
            let defaultOptions = {
                url: '/api/documents/import-order-excel',
                maxFilesize: 20,
                headers: authHeader(),
                previewTemplate: this.template,
                createImageThumbnails: false,
                clickable: '#documentDropZone',
                paramName: "user_file_upload"
            }
            return {...defaultOptions, ...this.options};
        }
    },

    data: function () {
        return {
            isUploading: false,
        }
    },

    methods: {
        sendingEvent: function (file, xhr, formData) {
            this.isUploading = true;
            formData.append('name', file.name.split('.').slice(0, -1).join('.'));
            formData.append('columns', JSON.stringify(
                {
                    username: 'A',
                    id: 'G',
                    executionProbability: 'M',
                    clientComplexity: 'K',
                    operationComplexity: 'L',
                    mainDestination: 'Q',
                }
            ));
        },

        fileSuccess: function (file, response) {
            this.$refs.dropzone.removeFile(file);
            this.isUploading = false;
            this.$emit('documentSaved', response);
        },

        fileCompleted: function (file) {
            this.queueSize--;

            if (this.queueSize === 0) {

            }
        },

        filesAdded: function (files) {
            this.queueSize = files.length;
        },

    },

    components: {
        vue2Dropzone
    }
}
</script>
